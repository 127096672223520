<template>
    <div class="card-layout" >
        <div class="card-layout__languages">
            <languages variant="link" toggle-class="card-layout__language-selector" />
        </div>
        <div class="card-layout__card">
            <router-view />
        </div>
    </div>
</template>

<script>
import Languages from "@/components/Languages";
export default {
    name: "card-layout",
    components: { Languages }
}
</script>
<style lang="scss">
.card-layout {

    background: #eeeeee;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    &__card {
        background-color: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 0px 30px rgba(#000000, 0.05);
        padding: 60px 80px;
        width: 100%;
        max-width: 852px;

        @media(max-width: 768px) {
            padding: 40px 20px;
            margin-top: 20px;
        }
    }

    &__languages {
        position: absolute;
        top: 75px;
        right: 75px;

        @media (max-width: 450px) {
            top: 20px;            
            right: 20px;
        }
    }
}
</style>